import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
export default {
  name: 'modelxManage',
  data: function data() {
    return {
      modelxManageProdUrl: 'https://modelx.longfor.com/projectFigure',
      modelxManageTestUrl: 'https://modelx-pre.longfor.com/projectFigure',
      isloading: false
    };
  },
  methods: {
    iframeOnload: function iframeOnload() {
      this.isloading = false;
    }
  },
  computed: {
    modelxManageUrl: function modelxManageUrl() {
      this.isloading = true;
      return window.location.host.indexOf('productreview.longfor.com') > -1 ? "".concat(this.modelxManageProdUrl, "?type=productaudit&bizId=").concat(this.$store.state.prj.prjId, "&spaceId=").concat(this.$store.state.prj.spaceId) : "".concat(this.modelxManageTestUrl, "?type=productaudit&bizId=").concat(this.$store.state.prj.prjId, "&spaceId=").concat(this.$store.state.prj.spaceId);
    }
  }
};