var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isloading,
          expression: "isloading"
        }
      ],
      staticClass: "modelxManage"
    },
    [
      _c("iframe", {
        attrs: { src: _vm.modelxManageUrl, frameborder: "0" },
        on: { load: _vm.iframeOnload }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }